<template>
    <div class="hour-question">
        <div class="row">
            <div class="col-8 title header">{{title}}</div>
            <div class="col-4" style="position:relative;">
                <div v-if="helpItem('hour_input')" class="help-item help-item-input">
                    {{help.text}}
                    <div class="arrow"><div class="arrow-inner"></div></div>
                </div>
                <input type="number" name="data" :min="min" :max="max" v-model="value" @focusout="lostFocus('hour_input')">
            </div>
        </div>

        <div v-if="helpItem('hour_slider')" class="help-item help-item-slider">
            {{help.text}}
            <div class="arrow"><div class="arrow-inner"></div></div>
        </div>

        <SlideNumber v-model="value" @lostfocus="lostFocus('hour_slider')" :min="min" :max="max"></SlideNumber>
    </div>
</template>

<script>

export default {

    props: {
        'title': {
            type: String,
        },
        'min' : {
            type: Number,
            default: 0,
        },
        'max' : {
            type: Number,
            default: 40,
        },
        'help' : {
            type:[Object,Boolean],
            default:false,
        },
    },

    data() {
        return {
            value: 0,
            valueChanged : false,
        };
    },

    watch : {

        value(value,event) {

            if (value < this.min) {
                value = this.min;
            }
            // if (value > this.max) {
            //     value = this.max;
            // }
            this.value = value;
            this.$emit('update:modelValue',value);
        },

    },


    computed: {
    },

    methods : {


        lostFocus(type) {
            if ( this.help!==false && this.help.type==type) {
                this.valueChanged = true;
                this.$emit('nextHelp');
            }
        },

        helpItem(type) {
            if ( this.help==false || this.valueChanged) return false;
            return this.help.type==type;
        },

    }

};
</script>

<style lang="scss" scoped>
    @import '../../sass/_variables.scss';

    .hour-question {
        padding: 0.5rem 0;

        input {
            float:right;
            width: 4rem;
            font-size: 1.25rem;
            text-align: center;
            border:solid 1px $gray;
            color:$orange;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
    }

    .help-item {
        position:absolute;
        top:-1rem;
        left:50%;
        transform:translateX(-50%);
        width:14rem;
        padding:0.5rem;
        text-align:left;
        border:solid 2px $orange-light;
        border-radius:3px;
        box-shadow:3px 3px 3px $dark-gray;
        background-color:$white;
        z-index:9999999!important;

        .arrow {
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -24px;
            border-width: 24px;
            border-top-width: 40px;
            border-style: solid;
            border-color: $orange-light transparent transparent transparent;
        }
        .arrow-inner {
            content:" ";
            position: absolute;
            top: -41px;
            left: 50%;
            margin-left: -22px;
            border-width: 22px;
            border-top-width: 36px;
            border-style: solid;
            border-color: $white transparent transparent transparent;
        }
    }
    .help-item-input {
        top:-10rem;
        left:auto;
        right:-6rem;
        .arrow {
            left:85%;
        }
    }

</style>
