require('./bootstrap');

import { createApp } from 'vue'
import QuestionSlider from './components/QuestionSlider.vue'
import ResultsGraph from './components/ResultsGraph.vue'


function registerComponents(app) {
    let files = require.context('./', true, /\.vue$/i);
    files.keys().map(function(key){
        let name = key.split('/').pop().split('.')[0];
        app.component(name, files(key).default);
    });
}

// Determine which vue app #app or #resultsapp
let appContainer = document.getElementById('app');
let resultsappContainer = document.getElementById('resultsapp');

if (appContainer!==null) {
    const app = createApp({
        components : {
            QuestionSlider: QuestionSlider,
        },
    })
    registerComponents(app);
    app.mount('#app')
}

if (resultsappContainer!==null) {
    const app = createApp({
        components : {
            ResultsGraph: ResultsGraph
        },
    })
    registerComponents(app);
    app.mount('#resultsapp')
}

$(document).ready(function() {
    $(".acc-tabs .tab-title").on("click", function(e) {

        e.preventDefault();
        var $this = $(this);

        if (!$this.hasClass("active")) {
            $(".acc-tabs .tab-content").slideUp(400);
            $(".acc-tabs .tab-title").removeClass("active");

            setTimeout(function() {
                var offset = $this.offset().top - 20;
                $('html, body').animate({
                    scrollTop: offset
                }, 400);
            }, 400);
        }

        $this.toggleClass("active");
        $this.next().slideToggle();
    });
});




