<template>
    <div class="energy-question my-sm-4">
        <div v-if="helpItem('energy_slider')" class="help-item">
            {{help.text}}
            <div class="arrow"><div class="arrow-inner"></div></div>
        </div>

        <p>Dit is een...</p>
        <div class="row">
            <div class="col-6 header">
                energiezuiger
            </div>
            <div class="col-6 header text-right">
                energiegever
            </div>
        </div>

        <slide-steps v-model="value"></slide-steps>

    </div>
</template>

<script>

export default {

    props: {
        'help' : {
            type:[Object,Boolean],
            default:false,
        },
    },

    data() {
        return {
            value: 0,
            valueChanged : false,
        };
    },

    watch : {

        value(value) {
            if ( !this.valueChanged) {
                this.valueChanged = true;
                if (this.help!==false) {
                    this.$emit('nextHelp');
                }
            }

            this.$emit('update:modelValue',value);
        },

    },

    methods : {

        helpItem(type) {
            if ( this.help==false || this.valueChanged) return false;
            return this.help.type==type;
        },

    }


};
</script>

<style lang="scss" scoped>
    @import '../../sass/_variables.scss';

    .energy-question {
        @media screen and (max-width: 450px){
            .header {
                font-size: 1.2rem;
            }
        }
    }

    .help-item {
        position:absolute;
        top:29rem;
        @media screen and (max-width: 500px){
            top: 26rem;
        }
        left:50%;
        transform:translateX(-50%);
        width:14rem;
        padding:0.5rem;
        text-align:left;
        font-family: "Hind", sans-serif;
        font-size: 1rem;
        font-weight: normal;
        border:solid 2px $orange-light;
        border-radius:3px;
        box-shadow:3px 3px 3px $dark-gray;
        background-color:$white;
        z-index:9999999!important;

        .arrow {
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -24px;
            border-width: 24px;
            border-top-width: 40px;
            border-style: solid;
            border-color: $orange-light transparent transparent transparent;
        }
        .arrow-inner {
            content:" ";
            position: absolute;
            top: -41px;
            left: 50%;
            margin-left: -22px;
            border-width: 22px;
            border-top-width: 36px;
            border-style: solid;
            border-color: $white transparent transparent transparent;
        }
    }


</style>
