<template>
    <img class="energy-icon" :src="icon" :alt="energy" />
</template>

<script>

export default {

    props: {
        energy: {
            type: Number,
        },
    },

    computed: {
        energy10() {
            return Math.round( (this.energy + 1) * 4.5 ) + 1;
        },

        icon() {
            let energy = this.energy10;
            if (energy<10) {
                energy = '0'+energy;
            }
            else {
                energy = energy +'';
            }
            return '/images/icons/batterij_'+energy+'.png';
        },

    },

    methods : {
    }

};
</script>

<style lang="scss" scoped>
    @import '../../sass/_variables.scss';

    .energy-icon {
        margin-top: -1px;
        height: 1rem!important;
        width: auto!important;
    }
</style>
