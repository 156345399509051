<template>
    <div class="slide-steps">
        <div class="slide-line"></div>
        <div class="slide-options">
            <div class="slide-option" :class="selectedOptionClass(index)" v-for="(value,index) in values" @click="selectOption(index)"></div>
        </div>
    </div>
</template>

<script>

export default {

    props : ['modelValue'],

    data() {
        return {
            values : [-1,-0.5,0,0.5,1],
            index : 2,
        };
    },


    computed : {

    },

    methods : {

        selectedOptionClass(index) {
            if (this.index==index) {
                return 'selected';
            }
        },

        selectOption(index) {
            this.index =  index;
            this.$emit('update:modelValue',this.values[this.index]);
        },

    }

};
</script>

<style lang="scss" scoped>
    @import '../../sass/_variables.scss';

    .slide-steps {
        position: relative;
        margin:1rem 0;
        height:3rem;

        @media screen and (max-width: 450px) {
            margin: 0 0 1rem;
        }

        .slide-line {
            position:absolute;
            top:1.5rem;
            width: 100%;
            height: 2px;
            background-color:$gray;
        }

        .slide-options {
            position:absolute;
            top:.75rem;
            width:100%;
            display:flex;
            justify-content: space-between;

            .slide-option {
                display: block;
                height: 1.5rem;
                width: 1.5rem;
                background-color: $white;
                border-radius: 50%;
                border:solid 2px $gray;
                cursor:pointer;
            }
            .slide-option.selected {
                background-color: $orange;
            }
        }

    }

</style>
