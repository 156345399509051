<template>
    <div class="question-slider">

        <div v-if="!isReady()">
            <div class="progress">
                <div :style="progressWidthStyle()" class="progress-bar" role="progressbar" :aria-valuenow="activeSlider" aria-valuemin="1" :aria-valuemax="questions.length"></div>
            </div>
        </div>

        <div class="row row justify-content-md-center">
            <div class="col-lg-9 col-xl-7">

                <div id="question-slider-content" class="carousel slide" data-interval="false" data-ride="carousel" data-touch="false">
                    <div class="carousel-inner">

                        <template v-for="(question,index) in questions">
                            <Question :question="question" :index="index + 1" :active="isActiveSlider(index+1)" :help="index==0" @update="updateAnswers(question.id,$event)"></Question>
                        </template>

                    </div>

                    <div v-if="!isReady()">
                        <div v-if="activeSlider>1" class="control-button control-button-prev float-left" @click="prevSlide()"><div class="arrow"></div>vorige</div>
                        <div v-if="activeSlider<questions.length" class="control-button control-button-next float-right" @click="nextSlide()">volgende<div class="arrow"></div></div>
                        <div v-else class="control-button control-button-ready float-right" @click="saveAnswers()">afronden</div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>

export default {

    props: {
        questions: {
            type: Array,
        },
        postRoute: {
            type: String,
        },
        group: {
            type: String,
        }
    },

    data : function() {
        return {
            el : {},
            activeSlider : 1,
            questionAnswers : [],
        };
    },

    mounted() {
        let self = this;
        this.el = $('#question-slider-content');

        // update slider
        $(this.el).on('slid.bs.carousel', function (event) {
            self.activeSlider = event.to + 1;
        })
    },

    computed: {
    },

    methods : {

        isReady() {
            return this.activeSlider >= this.questions.length + 1;
        },

        isActiveSlider(index) {
            return index === this.activeSlider;
        },

        progressWidthStyle() {
            let style = this.activeSlider / this.questions.length * 100 + "%";
            return 'width:'+style;
        },

        prevSlide() {
            this.slideTo( this.activeSlider-1 );
        },

        nextSlide() {
            this.slideTo( this.activeSlider+1 );
        },

        saveAnswers() {
            this.activeSlider = this.activeSlider + 1; // prevent double click
            axios({
                method: 'post',
                url: this.postRoute,
                data: {
                    'group' : this.group,
                    'scores' : this.questionAnswers,
                },
            }).then((data)=> {
                if ( typeof(data.data.redirect) !=='undefined' ) {
                    window.location.href = data.data.redirect;
                }
            });
        },

        slideTo(index) {
            $(this.el).carousel(index-1);
        },

        updateAnswers(questionID,answers) {
            let answerObject = answers;
            answerObject.question_id = questionID;
            let idx = this.questionAnswers.findIndex( e => e.id === questionID );
            if (idx>=0) {
                this.questionAnswers[idx] = answerObject;
            }
            else {
                this.questionAnswers.push(answerObject);
            }
        },

    }

};
</script>

<style lang="scss" scoped>
@import '../../sass/_variables.scss';

.question-slider {
    margin-top:2rem;

    .progress {
        border:solid 1px $gray;
        border-radius:1rem;
        padding:2px;

        .progress-bar {
            background-color: $orange!important;
            border-radius:1rem;
            transition:none;
        }

    }

}


#question-slider-content {
    margin:2rem 0;

    .carousel-item {
        padding:0 1rem;
    }

    .carousel-control {
        top: auto;
        bottom: 0;
        color: $orange;
        opacity: 1;
        transition:none;

        .arrow {
            font-size: 3rem;
        }
    }

    .control-button {
        position: relative;
        color:$orange;
        border:none;
        border-radius:0;
        box-shadow: none!important;
        margin:0 1rem;
        padding:0;
        cursor: pointer;
        font-size:1.5rem;

        .arrow {
            content: '';
            display: block;
            position: absolute;
            border: .5rem solid $orange;
            width: 2rem;
            height: 2rem;
            border-bottom: 0;
            border-left: 0;
        }

        @media (max-width: 575.98px) {
            font-size:1.5rem!important;

            .arrow {
                top:0.15rem!important;
                width: 1.5rem;
                height: 1.5rem;
            }
        }

    }
    .control-button:hover {
        color:$black;
        .arrow {border-color:$black;}
    }
    .control-button-prev {
        padding-left:1.5rem;
        .arrow {
            transform: rotate(-135deg);
            margin-left:-1.5rem;
        }
    }
    .control-button-next {
        padding-right:1.5rem;
        .arrow {
            top:0;
            right:1.5rem;
            transform: rotate(45deg);
            margin-right:-1.5rem;
        }
    }

    .control-button-ready {
        background-color:$orange;
        color: $white;
        padding:.75rem;
        margin-top:-0.5rem;

        &:hover {
            color: $white;
            background-color:$black;
        }
    }

}



</style>
