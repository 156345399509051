<template>
    <div class="results-graph" ref="root">
        <h3 class="label label-y label-top">Hier wil ik meer tijd aan besteden</h3>
        <h3 class="label label-x label-left">energie zuiger</h3>
        <h3 class="label label-x label-right">energie gever</h3>
        <h3 class="label label-y label-bottom">Hier wil ik minder tijd aan besteden</h3>
        <div class="as x-as"></div>
        <div class="as y-as"></div>

        <div v-for="(item,index) in items" class="item" :class="'item-'+index" :style="itemStyle(item,index)" @click="toggleItem(index)" @mouseover="toggleHoverItem(index)" @mouseleave="toggleHoverItem(index)">
            <div class="item-tip" :class="'item-tip-'+index">
                <h4>{{item.label}}</h4>
                Nu {{item.hoursReal}} uren in de week<br>
                Gewenst: {{item.hoursIdeal}} uren<br>
                Energiescore: <energyScore :energy="item.energyScore"></energyScore>
                <div class="arrow"><div class="arrow-inner"></div></div>
            </div>
        </div>

        <div v-for="(item,index) in items" class="item-label" :class="'item-label-'+index" :style="itemLabelStyle(item,index)" @click="toggleItem(index)" @mouseover="toggleHoverItem(index)" @mouseleave="toggleHoverItem(index)">
            {{item.label}}
        </div>

    </div>
</template>

<script>

import { ref, onMounted } from 'vue'
export default {

    props: {
        graphdata: {
            type: Object,
        },

        hideEmtpyCircles : {
            type: Boolean,
            default:true,
        },

        maxIntersectPercentage : {
            type: Number,
            default:20,
        },


    },

    data : function() {
        return {
            items : [],
            firstUpdate : true,
            firstToolTip : 0,
        };
    },

    setup() {
        const root = ref(null);

        return {
            root
        }
    },

    mounted() {
        window.addEventListener('load', () => {
            this.resize();
            this.calcItemSizes();
            window.addEventListener('resize', () => { this.resize() } );
        });
    },

    updated() {
        if (this.firstUpdate) {
            this.toggleItem(this.firstToolTip);
        }
        this.firstUpdate = false;
    },

    computed: {
    },

    methods : {

        resize() {
            let el = this.$refs.root;
            let width = el.offsetWidth;
            if (width==0) {
                let poll = window.setInterval(function(){
                    width = el.offsetWidth;
                    if (width>0) {
                        el.style.height = width+"px";
                        window.clearInterval(poll);
                    }
                },50);
            }
            el.style.height = width+"px";
        },

        calcItemSizes() {
            let items = [];
            let index = 0;
            let vUnit = 100 / (2 * this.graphdata.yMax);
            let hUnit = 100 / (2 * this.graphdata.xMax);
            for( var i in this.graphdata.items) {
                let item = this.graphdata.items[i];

                // Remove empty circles
                let isEmptyCircle = false;
                if ( this.hideEmtpyCircles ) {
                    if ( item.hoursReal==0 && item.hoursIdeal==0 ) {
                        isEmptyCircle = true;
                    }
                }

                if ( !isEmptyCircle ) {
                    // Calculate circle size
                    items[index] = {...item};
                    items[index].size = 5 + (item.hoursReal * vUnit * 0.95); // minimum 5%, maximum 90% (a bot of room)
                    items[index].xpos = item.x * hUnit + 50;
                    items[index].ypos = -item.y * vUnit + 50;
                    items[index].xlabel = items[index].xpos;
                    items[index].ylabel = items[index].ypos;
                    index ++;
                }

            }

            // sort on size (smaller items on top means bigger items first)
            items = items.sort( (a,b) => b.size - a.size );

            // intersect detection and removal
            if ( this.maxIntersectPercentage>0 ) {
                this.decreaseIntersections(items,true);
                this.decreaseIntersections(items,false);
            }

            // active tooltip
            for(var i = 0; i < items.length; i++) {
                if (items[i].tooltip) {
                    this.firstToolTip = i;
                }
            }

            this.items = items;
        },

        decreaseIntersections(items,moveCircles) {
            let numberOfItems = items.length;
            let numberOfIntersections = 0;
            for( let i = 0; i < numberOfItems-1; i++) {
                for( let j = i+1; j < numberOfItems; j++) {
                    let distance = this.calcDistance(items[i],items[j]);
                    let intersect = distance - items[i].size/2 - items[j].size/2;
                    if (intersect>0) {
                        intersect = 0;
                    }
                    else {
                        intersect = Math.abs(intersect);
                    }
                    if ( intersect > 0 ) {
                        let intersectPercentage = Math.round(intersect / items[j].size/2 * 100);
                        if ( intersectPercentage > this.maxIntersectPercentage ) {
                            numberOfIntersections++;
                            let diffLabel = 1.25; //(intersectPercentage-this.maxIntersectPercentage) / 2;
                            let diffX = 0.75;
                            let diffY = 0.25;
                            if (items[i].xpos > items[j].xpos) {
                                if (moveCircles) {
                                    items[i].xpos += diffX;
                                    items[j].xpos -= diffX;
                                }
                                items[i].xlabel += diffLabel;
                                items[j].xlabel -= diffLabel;
                            }
                            else {
                                if (moveCircles) {
                                    items[i].xpos -= diffX;
                                    items[j].xpos += diffX;
                                }
                                items[i].xlabel -= diffLabel;
                                items[j].xlabel += diffLabel;
                            }
                            if (items[i].ypos > items[j].ypos) {
                                if (moveCircles) {
                                    items[i].ypos += diffY;
                                    items[j].ypos -= diffY;
                                }
                                items[i].ylabel += diffLabel;
                                items[j].ylabel -= diffLabel;
                            }
                            else {
                                if (moveCircles) {
                                    items[i].ypos -= diffY;
                                    items[j].ypos += diffY;
                                }
                                items[i].ylabel -= diffLabel;
                                items[j].ylabel += diffLabel;
                            }
                        }
                    }
                }
            }
            return numberOfIntersections;
        },

        calcDistance(point1,point2) {
            let xdist = point1.xpos - point2.xpos;
            let ydist = point1.ypos - point2.ypos;
            let dist = Math.sqrt( Math.pow(xdist,2) + Math.pow(ydist,2) );
            return Math.round(dist);
        },

        itemStyle(item,index) {
            return `left:${item.xpos}%;top:${item.ypos}%;width:${item.size}%;height:${item.size}%;z-index:${index};`;
        },

        itemLabelStyle(item,index) {
            return `left:${item.xlabel}%;top:${item.ylabel}%;z-index:${10000+index};`;
        },

        toggleItem(index) {
            this.toggleElement(index,'item','show');
            this.toggleElement(index,'item-label','show');
        },

        toggleHoverItem(index) {
            this.toggleElement(index,'item','hover');
            this.toggleElement(index,'item-label','hover');
        },

        toggleElement(index,cssClass,toggleClass) {
            let items = Array.from(document.getElementsByClassName(cssClass));
            for (let i in items) {
                if (i != index) {
                    items[i].classList.remove(toggleClass);
                }
            }
            let el = document.getElementsByClassName(cssClass+'-'+index)[0];
            if (el.classList.contains(toggleClass)) {
                el.classList.remove(toggleClass);
            }
            else {
                el.classList.add(toggleClass);
            }
        }

    }

};
</script>

<style lang="scss" scoped>
@import '../../sass/_variables.scss';

.results-graph {
    position: relative;
    margin:4rem 0;
    border: solid 2px $gray;
    overflow:visible;
    @media (max-width: 575.98px) {
        margin: 4rem 1rem;
    }

    .label {
        position:absolute;
        background-color:transparent;
        font-size:1.25rem;
    }
    .label-y {
        text-align: center;
        width:100%;
    }
    .label-top {
        top:-2rem;
    }
    .label-bottom {
        bottom:-2.5rem;
    }
    .label-x {
        top:50%;
        margin-top:-1rem;
        width:10rem;
        text-align:center;
    }
    .label-left {
        left:-6.25rem;
        transform:rotate(-90deg);
    }
    .label-right {
        right:-6.25rem;
        transform:rotate(90deg);
    }

    .as {
        position:absolute;
        background-color:$gray;
    }
    .x-as {
        width:100%;
        top:50%;
        height:2px;
    }
    .y-as {
        height:100%;
        left:50%;
        width:2px;
    }

    .item {
        position:absolute;
        display: block;
        height: 50%;
        width: 50%;
        transform:translate(-50%,-50%);
        background-color: rgba($orange,0.35);
        border-radius: 50%;
        cursor:pointer;
        @media (max-width: 575.98px) {
            font-size:0.6rem;
        }

        .item-tip {
            position:absolute;
            display:none;
            top:-10rem;
            left:50%;
            transform:translateX(-50%);
            width:11rem;
            @media (max-width: 575.98px) {
                top:-8rem;
                width: 8rem;
                font-size:0.7rem;
            }
            padding:0.5rem;
            text-align:left;
            border:solid 2px $orange-light;
            border-radius:3px;
            box-shadow:3px 3px 3px $dark-gray;
            background-color:$white;
            z-index:9999999!important;

            h4 {
                font-size: 1.25rem;
                margin-bottom:0.1rem;
                @media screen and (max-width: 575.98px){
                    font-size: 1rem;
                }
            }


            .arrow {
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -24px;
                border-width: 24px;
                border-top-width: 40px;
                border-style: solid;
                border-color: $orange-light transparent transparent transparent;
            }
            .arrow-inner {
                content:" ";
                position: absolute;
                top: -41px;
                left: 50%;
                margin-left: -22px;
                border-width: 22px;
                border-top-width: 36px;
                border-style: solid;
                border-color: $white transparent transparent transparent;
            }
        }
    }

    .item.show {
        background-color: rgba($orange,0.85);
        z-index:99999!important;
        .item-tip {
            display:block;
        }
    }

    .item.hover:not(.show) {
        background-color: rgba($orange,0.75);
    }


    .item-label {
        position:absolute;
        display:block;
        transform:translate(-50%);
        margin-top:-0.5rem;
        font-size:0.85rem;
        text-align:center;
        cursor:pointer;
        opacity: 0.7;
        @media screen and (max-width: 575.98px){
            font-size: 0.7rem;
        }
    }

    .item-label.hover:not(.show) {
        opacity:1;
    }
    .item-label.show {
        opacity:1;
        z-index:9999999!important;
    }


}

</style>
