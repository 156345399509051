<template>
    <div class="slide-number">
        <div class="slide-line"></div>
        <div class="slide-selecter" :style="selecterStyle(modelValue)"></div>
        <div class="slide-options">
            <div class="slide-option" v-for="(value,index) in values" :class="slideOptionClass(index)">
                <div class="label">{{value}}</div>
            </div>
        </div>
        <div class="slide-mask"  @click="selectValue($event)" @mousedown="startDrag()" @mousemove="drag($event)" @mouseup="endDrag()" @touchstart.stop.prevent="startDrag()" @touchmove.stop.prevent="drag($event)" @touchend.stop.prevent="endDrag()"></div>
    </div>
</template>

<script>

export default {

    props : {
        'modelValue' : {
            type:Number,
        },
        'min' : {
            type: Number,
            default: 0,
        },
        'max' : {
            type: Number,
            default: 80,
        },
        'step' : {
            type: Number,
            default: 5,
        },
    },


    data() {
        return {
            isDragging : false,
        };
    },


    computed : {

        values() {
            let numberOfSteps = (this.max - this.min) / this.step;
            return Array.from(Array.from(Array(numberOfSteps+1).keys()), x => this.min + x * this.step);
        },

    },

    methods : {

        selectValue(event) {
            let relativeValue = event.layerX / event.target.offsetWidth;
            this.updateValue(relativeValue);
        },

        updateValue(relativeValue) {
            let value = Math.round( this.min + (this.max - this.min) * relativeValue );
            this.$emit('update:modelValue',value);
            this.$emit('update',value);
        },

        slideOptionClass(index) {
            let c = 'slide-option-'+index;
            if ( index % 2 == 0) {
                c += ' slide-option-even';
            }
            else {
                c += ' slide-option-odd';
            }
            return c;
        },

        selecterStyle(value) {
            let leftPos = (value - this.min) / (this.max - this.min) * 100;
            return `left:${leftPos}%;`;
        },

        startDrag() {
            this.isDragging = true;
        },

        drag(event) {
            if (this.isDragging) {
                let rect = event.target.getBoundingClientRect();
                let left = 0;
                if ( event.type==='touchmove' ) {
                    left = event.touches[0].clientX - rect.left;
                }
                else {
                    left = event.clientX - rect.left;
                }
                let relativeValue = left / event.target.offsetWidth;
                this.updateValue(relativeValue);
            }
        },

        endDrag() {
            this.isDragging = false;
            this.$emit('lostfocus');
        },


    }

};
</script>

<style lang="scss" scoped>
    @import '../../sass/_variables.scss';

    .slide-number {
        position: relative;
        margin:3rem 0.5rem 1rem;
        height:3rem;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @media screen and (max-width: 500px) {
            margin-top: 1rem;
        }

        .slide-line {
            position:absolute;
            top:1.5rem;
            width: 100%;
            height: 2px;
            background-color:$gray;
        }

        .slide-selecter {
            position:absolute;
            display: block;
            top:.75rem;
            height: 1.5rem;
            width: 1.5rem;
            margin-left:-0.75rem;
            background-color: $orange;
            border-radius: 50%;
            cursor:pointer;
            z-index:9999;
        }

        .slide-options {
            position:absolute;
            top:.75rem;
            width:100%;
            display:flex;
            justify-content: space-between;

            .slide-option {
                position:relative;
                display: block;
                height: 1.5rem;
                width: 2px;
                background-color:$gray;

                .label {
                    position:absolute;
                    top: - 1.5rem;
                    left: -1rem;
                    width:2rem;
                    text-align: center;
                }
            }

            .slide-option-odd {
                @media (max-width: 575.98px) {
                    display:none;
                }
            }

        }

        .slide-mask {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            cursor:pointer;
            z-index:999999;
        }

    }

</style>
