<template>
    <h2>Welcome</h2>
</template>

<script>

export default {

    data : function() {
        return {
        };
    },

    computed: {
    },

    methods : {
    }

};
</script>

<style lang="scss" scoped>
    @import '../../sass/_variables.scss';
</style>
