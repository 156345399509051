<template>
    <QuestionSlider :questions="questions"></QuestionSlider>
</template>

<script>

/*

"id" => 1
"question" => "Hoeveel uur besteed je aan werk"
"description" => "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
"info" => """
"image" => array:7 [▼
  "path" => "//localhost:3004/storage/images/prepared-piano.jpg"
  "thumb" => "//localhost:3004/storage/images/prepared-piano/prepared-piano-thumb.jpg"
  "width" => 510
  "height" => 325
  "alt" => ""
  "caption" => ""
]
"order" => 1


 */


export default {

    props: {
        'questions': {
            type: String,
        },
    },

    computed: {
    },

    methods : {
    }

};
</script>

<style lang="scss" scoped>
    @import '../../sass/_variables.scss';
</style>
