<template>
    <div class="carousel-item" :class="activeClass">
        <div class="d-block w-100">

            <div class="popup-icon popup-icon-open float-right" :class="popupClass" data-toggle="modal" :data-target="'#'+uniquePopupID"><i class="fas fa-info-circle"></i></div>
            <div v-if="hasPopup" class="modal" :id="uniquePopupID" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="close popup-icon popup-icon-close" data-dismiss="modal" aria-label="Close"><i class="fas fa-times-circle float-right"></i></div>
                        <div v-html="question.info"></div>
                    </div>
                </div>
              </div>
            </div>

            <div class="index">{{index}}</div>


            <div class="info">
                <h3>{{question.question}}</h3>
                {{question.description}}
            </div>

            <div class="image" v-if="question.image">
                <img
                     :src="question.image.path"
                     class="img-fluid"
                     :alt="question.question"
                     :height="question.image.height"
                     :width="question.image.width"
                     :srcset="question.image.srcset"
                />
            </div>

            <div class="row">
                <div class="col-12">
                    <HoursQuestion title="Zoveel uur besteed ik er nu aan:" :min="question.min_hours" :max="question.max_hours" v-model="answers.hours_real" :help="showHelp('hour_slider')" @nextHelp="nextHelp()"></HoursQuestion>
                </div>
                <div class="col-12">
                    <HoursQuestion title="Zoveel uur wil ik er aan besteden:" :min="question.min_hours" :max="question.max_hours" v-model="answers.hours_ideal" :help="showHelp('hour_input')" @nextHelp="nextHelp()"></HoursQuestion>
                </div>
            </div>

            <EnergyQuestion v-model="answers.energy" :help="showHelp('energy_slider')" @nextHelp="nextHelp()"></EnergyQuestion>

        </div>
    </div>
</template>

<script>

export default {

    props: {
        'question': {
            type: Object,
        },
        'index' : {
            type: Number,
        },
        'active' : {
            type: Boolean,
            default: false,
        },
        'help' : {
            type: Boolean,
            default: false,
        },
    },

    data : function() {
        return {
            answers : {
                hours_real:0,
                hours_ideal:0,
                energy: 0,
            },
            helpItems : [
                { 'type': 'hour_slider', 'active' : true, 'text':'Sleep het oranje bolletje om je uren in te voeren.' },
                { 'type': 'hour_input', 'active' : false, 'text':'Je kunt ook het aantal uren hier ingeven. Ook handig als de slider niet ver genoeg gaat. Type een getal.' },
                { 'type': 'energy_slider', 'active' : false, 'text':'Sleep het oranje bolletje meer naar links of meer naar rechts om aan te geven of dit je energie kost of oplevert.' },
            ]
        };
    },

    watch : {

        'answers.hours_real' : function(value) {
            this.$emit('update',this.answers);
        },
        'answers.hours_ideal' : function(value) {
            this.$emit('update',this.answers);
        },
        'answers.energy' : function(value) {
            this.$emit('update',this.answers);
        },

    },

    mounted() {
        this.$emit('update',this.answers);
    },

    computed: {
        uniquePopupID() {
            return 'info-popup-'+this.question.id;
        },

        hasPopup() {
            return this.question.info!==null;
        },

        popupClass() {
            if ( !this.hasPopup ) return 'info-popup-empty';
            return '';
        },

        activeClass() {
            if (this.active) return 'active';
            return '';
        },

    },

    methods : {

        showHelp(type) {
            if (this.help) {
                let index = this.helpItems.findIndex( e => e.active==true );
                if (index<0) return false;
                let help = this.helpItems[index];
                if (help.type==type) return help;
            }
            return false;
        },

        nextHelp() {
            if (this.help) {
                let index = this.helpItems.findIndex( e => e.active==true );
                if (index>=0) {
                    this.helpItems[index].active = false;
                    if ( index<this.helpItems.length-1 ) {
                        this.helpItems[index+1].active = true;
                    }
                }
            }
        },

    }

};
</script>

<style lang="scss" scoped>
    @import '../../sass/_variables.scss';


    .popup-icon {
        cursor: pointer;
        color:$orange;
        font-size:24px;
    }
    .popup-icon:hover {
        color: $black!important;
    }
    .info-popup-empty {
        opacity: 0.5;
    }
    .popup-icon-close {
        opacity: 1;
    }

    .index {
        width: 50px;
        height: 50px;
        background-color: $orange;
        color: $white;
        text-align: center;
        line-height: 50px;
        font-size: 1.5rem;
        margin-bottom: 0.75rem;
    }

    .info {
        height: 6.5rem;
        @media (max-width: 575.98px) {
            height: 9rem;

            h3 {
                font-size: 1.5rem;
            }
        }
    }

    .image {
        margin: 1rem 0;
    }

</style>
